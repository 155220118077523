@use '@material/textfield';
@use '@angular/material' as mat;
@use 'sass:math';
@use 'sass:map';

// ignore the missing identifier error
mat.$theme-ignore-duplication-warnings: true;

@import 'proficloud-theme';

@import 'custom-material-theme.scss';

// show label for more dense inputs as well
.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: inline !important;
}

// @import 'https://fonts.googleapis.com/icon?family=Material+Icons';

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/material-icons/material-icons.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons', serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

@import './styles/variables.scss';
@import './styles/typography.scss';

@import './styles/styleguide.scss';
@import './styles/base-styles.scss';

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 ==$u2 and $u1 ==$u3 and $u1 ==$u4 {
    & {
      font-size: $min-font-size;

      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip_unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip_unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

/*   minimum and maximum considered viewports widths  */
$min_vp_width: 520px;
$max_vp_width: 1200px;

/*   h1 minimum and maximum font size   */
$h1_min_font: 24px;
$h1_max_font: 40px;

/* .responsive-h1 {
  @include fluid-type($min_vp_width, $max_vp_width, $h1_min_font, $h1_max_font);
} */

/*    paragraph minium and maximum font size  */
$p_min_font: 14px;
$p_max_font: 18px;

.responsive-p {
  @include fluid-type($min_vp_width, $max_vp_width, $p_min_font, $p_max_font);
}

// standard colors
:root {
  // grays
  --white: #fff;
  --black: #000;
  --jet-black: #202020;
  --regent-gray: #929ba1;
  --black-haze: #ebecec;
  --white-smoke: #f5f5f5;
  --alabaster: #fafafa;

  // colors
  --persian-green: #0098a1;
  --mosque: #026776;

  --pistachio: #93c005;
  --shiraz: #be0f1b;
  --warning-yellow: #ffa800;
  --success-green: #09ae3b;

  // gradients
  --error-grad: linear-gradient(to left, rgba(239, 82, 35, 1) 0%, rgba(209, 20, 34, 1) 100%);
  --info-grad: linear-gradient(to top right, #60e3c5 0%, #0dc5ce 100%);
  --phoenix-grad: linear-gradient(to left, rgba(147, 192, 5, 1) 0%, rgba(0, 153, 161, 1) 100%);
  --warning-grad: linear-gradient(to top right, #fec02e, #ffe240);

  --device-error-grad: linear-gradient(to right, rgb(255, 96, 88), rgb(255, 111, 69));
  --device-warning-grad: linear-gradient(to right, rgb(254, 192, 46), rgb(255, 226, 64));

  --max-viewport: 1000px;
  --min-viewport: 400px;
  --h1-fontsize-min: 20px;
  --h1-fontsize-max: 40px;
}

// named color backgrounds
.persianGreenBackground {
  background: var(--primary);
  color: white;
}

.persianGreenText {
  color: var(--primary);
}

.jetBlackText {
  color: var(--jet-black);
}

.jetBlackLink:visited {
  color: var(--jet-black);
}

.mosqueBackground {
  background: var(--secondary);
  color: white;
}

.pistachioBackground {
  background: var(--pistachio);
  color: white;
}

.errorBackground {
  background: var(--error-grad);
  color: white;
}

.infoBackground {
  background: var(--info-grad);
}

.phoenixBackground {
  background: var(--phoenix-grad);
}

.warningBackground {
  background: var(--warning-grad);
}

.colorBox {
  width: 200px;
  height: 100px;
  margin: 10px;
}

.gradientBox {
  width: 400px;
  height: 80px;
  margin: 10px;
}

/**    TYPOGRAPHY   */

h1,
h2,
h3,
h4,
h5,
strong {
  font-weight: 500;
}

/**    HEADLINES & SUBHEADLINE */
.h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 45px;
}

.sh1 {
  font-weight: 300;
  font-size: 30px;
  line-height: 35px;
}

.h2 {
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
}

.sh2 {
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
}

.h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}

.h4 {
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
}

/**   BODY    */
.p1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.p2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

/**    TRY FLUID   */
// https://css-tricks.com/snippets/css/fluid-typography/

// below minium considered viewport width: minimum css size
.headline1 {
  font-size: 20px;
  letter-spacing: -2px; // up to 0 px, for a delta of 2
}

.paragraph1 {
  font-size: 14px; // up to 18, for a delta of 4
}

// between minium and maximum viewport width: fluid css size
@media screen and (min-width: 400px) {
  .headline1 {
    // font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
    font-size: calc(var(--h1-fontsize-min) + 20 * ((100vw - var(--min-viewport)) / 600));
    letter-spacing: calc(-2px + 2 * ((100vw - 400px) / 600));
  }

  .paragraph1 {
    font-size: calc(14px + 4 * ((100vw - 400px) / 600));
  }
}

// above maximum considered viewport width: maximum css size!
@media screen and (min-width: 1000px) {
  .headline1 {
    font-size: 40px;
    letter-spacing: 0px;
  }

  .paragraph1 {
    font-size: 18px;
  }
}

.test {
  font-size: calc(calc(var(--h1-fontsize-max) - var(--h1-fontsize-min)));
}

// small
@media screen and (max-width: $mobileUpTo) {
  .p1 {
    font-size: 14px;
  }
}

// medium
@media screen and (min-width: $mobileUpTo) and (max-width: $tabletUpTo) {
  .p1 {
    font-size: 16px;
  }
}

// large
@media screen and (min-width: $tabletUpTo) {
  .p1 {
    font-size: 16px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--primary-font), var(--secondary-font);
  background: #026776;
  color: var(--jet-black);
}

pre,
input,
textarea {
  font-family: 'source-sans', sans-serif;
  color: var(--jet-black);
}

.linkColor {
  color: var(--primary);
}

.linkColor:hover {
  color: #026776;
}

input,
textarea,
button {
  font-family: inherit;
}

b {
  font-weight: 500;
}

#app-reactive-ticket {
  display: block;
}

.lds-dual-ring {
  border-radius: 50%;
  border: 5px solid #222;
  box-sizing: border-box;
  border-color: #222 transparent #222 transparent;
  animation: lds-dual-ring 0.7s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

flex-col {
  display: flex;
  flex-direction: column;
}

flex-row {
  display: flex;
}

div-grow {
  display: block;
  flex-grow: 1;
}

div-relative {
  display: block;
  position: relative;
}

flex-col-grow {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

flex-col-center-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

flex-col-start-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

flex-col-center-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

flex-row-grow {
  display: flex;
  flex-grow: 1;
}

flex-row-center {
  display: flex;
  justify-content: center;
}

flex-row-center-end {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

flex-row-end {
  display: flex;
  justify-content: flex-end;
}

flex-row-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

flex-row-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

flex-row-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

flex-row-between {
  display: flex;
  justify-content: space-between;
}

flex-row-around {
  display: flex;
  justify-content: space-around;
}

flex-row-between-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

flex-row-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/**
Button styles from florian
*/

.proficloud {
  background-color: var(--primary);
  /* Green */
  min-width: 200px;
  height: 48px;
  padding-left: 20px;
  padding-right: 20px;
  border: 0px solid;
  color: var(--primary);
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 3px 0 rgba(0, 0, 0, 0.19);
  outline: none;
}

.primary {
  background-color: var(--primary);
  color: #fff;
  transition-duration: 0.2s;
}

.primary:hover:enabled {
  background-color: #026776;
  color: #fff;
}

.primary:active {
  background-color: #026776;
  color: #fff;
  box-shadow:
    0 0px 0px 0 rgba(0, 0, 0, 0),
    0 0px 0px 0 rgba(0, 0, 0, 0);
}

.secondary {
  background-color: #fff;
  color: var(--primary);
  border: 1px solid var(--primary);
}

.secondary:hover:enabled {
  background-color: var(--white-smoke);
  color: #026776;
  border: 1px solid #026776;
}

.secondary:active {
  background-color: var(--white-smoke);
  color: #026776;
  border: 1px solid #026776;
  box-shadow:
    0 0px 0px 0 rgba(0, 0, 0, 0),
    0 0px 0px 0 rgba(0, 0, 0, 0);
}

.delete {
  background-color: #dc3545;
  color: #fff;
}

.delete:hover {
  background-color: #c82131;
  color: #fff;
}

.delete:active {
  background-color: #b40d1d;
  color: #fff;
}

button:disabled {
  background-color: #ebecec;
  color: #929ba1;
  cursor: not-allowed;
  box-shadow:
    0 0px 0px 0 rgba(0, 0, 0, 0),
    0 0px 0px 0 rgba(0, 0, 0, 0);
}

a.pcLink {
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/** metrial paper */

.paper-1 {
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
}

.paper-2 {
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
}

.paper-3 {
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
}

.paper-4 {
  box-shadow:
    0 14px 28px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(0, 0, 0, 0.22);
}

.paper-5 {
  box-shadow:
    0 19px 38px rgba(0, 0, 0, 0.3),
    0 15px 12px rgba(0, 0, 0, 0.22);
}

.footerLink {
  color: #949494;
  padding: 5px 0;
  font-size: 18px;
  text-decoration: none;
}

.footerLink:visited {
  color: #949494;
}

.footerCopyright {
  margin-top: 30px;
  font-size: 16px;
  color: #afafaf;
}

.markdown > p > a {
  color: var(--primary);
  text-decoration: none;
}

// gateway
.gatewayMarkdown > ol {
  margin: 0;
}

.markdown > h3 {
  font-weight: 500;
  font-family: source-sans;
}

html,
body {
  height: 100%;
}

.h2-500 {
  font-size: 28px;
  font-weight: 500;
  font-family: source-sans;
}

.semibold {
  font-weight: 500;
  font-family: source-sans;
}

// viewport width: 300 min, max 1600
// size: min 14px, max 26px
.fluidTest {
  font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
}

// viewport width: 300 min, max 1600
// size: min 14px, max 26px
.fluidTest {
  font-size: calc(14px + (26 - 14) * ((100vw - 500px) / (1600 - 500)));
}

.fluidDeviceMargin {
  padding-right: calc(0px + (40 - 0) * ((100vw - 500px) / (1600 - 500)));
  padding-left: calc(0px + (40 - 0) * ((100vw - 500px) / (1600 - 500)));
}

.fluidTitleMargin {
  margin-left: calc(20px + (40 - 20) * ((100vw - 500px) / (1600 - 500)));
}

.fluidTitleMarginLR {
  margin-left: calc(20px + (40 - 20) * ((100vw - 500px) / (1600 - 500)));
  margin-right: calc(20px + (40 - 20) * ((100vw - 500px) / (1600 - 500)));
}

.fluidServiceHeadline {
  font-size: calc(18px + (28 - 18) * ((100vw - 500px) / (1600 - 500)));
}

.fluidServiceSubHeadline {
  font-size: calc(12px + (16 - 12) * ((100vw - 500px) / (1600 - 500)));
}

// small
@media screen and (max-width: $mobileUpTo) {
  .tabItem {
    // background: red;
    font-size: 18px;
    margin-right: 15px;
  }
}

// medium
@media screen and (min-width: $mobileUpTo) and (max-width: $tabletUpTo) {
  .tabItem {
    // background: orange;
    font-size: 20px;
    margin-right: 20px;
  }
}

// large
@media screen and (min-width: $tabletUpTo) {
  .tabItem {
    font-size: 22px;
    margin-right: 22px;
  }
}

.tabItem {
  color: var(--jet-black);
  border-bottom: 4px solid transparent;
  cursor: pointer;
  position: relative;
}

.tabItem:hover {
  color: var(--secondary);
}

.tabItem.selected {
  color: var(--primary);
  border-bottom: 4px solid var(--primary);
}

.tooCloseRight > * .md-drppicker {
  left: unset !important;
  right: 0px !important;
}

.tooCloseBottom > * .md-drppicker {
  bottom: 0px !important;
}

// Required in order to customize the dropdown button of the filterbar
.dropdownPanelClass {
  margin-top: 35px !important;
}

// NGX-Scrollbar overrides
.ng-scrollbar-limit-width .ng-scroll-content {
  max-width: 100%;
}

// utility classes
.pad-16 {
  padding: 16px;
}

.italic {
  font-style: italic;
}

.fs-16 {
  font-size: 16px;
}

.box-bb {
  box-sizing: border-box;
}

.bg-white {
  background-color: white;
}

.bg-alabaster {
  background-color: var(--alabaster);
}

.width-full {
  width: 100%;
}

// headlines
.pc-h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}

.pc-p1 {
  font-size: 16px;
  line-height: 20px;
}

.pc-p2 {
  font-size: 12px;
  font-style: italic;
  line-height: 14px;
}

.formula-metric {
  display: inline-block;
  margin: 2px;
  color: var(--jet-black);
  background-color: var(--white);
  padding-left: 5px;
  padding-right: 5px;
  margin: 2px;
  box-shadow: 0px 1px 3px #0000001a;
  border-radius: 8px;
}

.formula-metric .tag {
  display: inline;
  color: var(--primary);
  background: rgb(36, 176, 244, 0.2);
  border-radius: 30%;
  height: 18px;
  padding: 0px 4px 0px 4px;
  font-size: 12px;
  margin-left: 4px;
  text-wrap: nowrap;
}

.formula-metric.filtering {
  color: var(--primary);
}

.formula-operator {
  font-weight: 600;
  padding: 5px;
}

.formula-number {
  padding: 5px;
}

.formula-number.filtering {
  color: var(--primary);
}

.formula-unit {
  display: inline-block;
  margin: 2px;
  color: var(--jet-black);
  background-color: var(--white);
  padding-left: 5px;
  padding-right: 5px;
  margin: 2px;
  box-shadow: 0px 1px 3px #0000001a;
  border-radius: 8px;
}

.formula-unit.filtering {
  color: var(--primary);
}

.remove-formula-element {
  color: var(--primary);
  cursor: pointer;
  padding-left: 5px;
  font-size: 24px;
  bottom: 8px;
  position: relative;
}

.custom-density-2 {
  @include mat.all-component-densities(-2);
}

.infoBanner {
  padding: 12px;
  border-radius: 4px;
  gap: 16px;
}

.infoBanner a {
  white-space: nowrap;
}

// Material tabs overrides specific to components
.menu__tabs .mat-mdc-tab-body-wrapper {
  height: 100%;
}

.subs-tabs .mat-mdc-tab-header {
  width: 340px;
}

.menu__tabs .mdc-tab__content .mdc-tab__text-label {
  font-weight: 400;
  font-size: 15px;
  color: var(--text-text-primary, #202020);
}

.menu__tabs .mdc-tab__content .mdc-tab__text-label:hover {
  color: var(--primary);
}

.menu__tabs button:disabled {
  background-color: #fff;
}

.menu__tabs .mat-mdc-tab-header {
  border-bottom: 1px solid #ddd;
  margin-right: 48px;
}

.custom-tabs .mdc-tab__content .mdc-tab__text-label {
  font-weight: 400;
  font-size: 16px;
  color: var(--text-text-primary, #202020);
}

.custom-tabs .mdc-tab__content .mdc-tab__text-label:hover {
  color: var(--primary);
}

.device-tabs-arrows .ng-scroll-content {
  display: inline;
}

.custom-tabs button:disabled {
  background-color: #fff;
}

.custom-tabs .mat-mdc-tab-header {
  border-bottom: 1px solid #ddd;
}

.custom-tabs .mat-mdc-tab-body {
  margin-top: 24px;
}

.filter-device .pc-icon-dropdown__container {
  box-shadow: none !important;
}

.filter-device .pc-icon-dropdown__selection--icon-only {
  position: relative !important;
  top: 0 !important;
  right: 0 !important;
}

.dms-filter-sort .pc-button {
  box-shadow: none !important;
  background: white !important;
  width: 48px !important;
  height: 48px !important;
  padding: 0 !important;
  min-width: 48px !important;
  min-height: 48px !important;
}

.dms-filter-sort.active::after {
  content: ' ' !important;
  display: block !important;
  width: 8px !important;
  height: 8px !important;
  background-color: var(--primary) !important;
  border-radius: 50% !important;
  top: -36px !important;
  right: -36px !important;
  margin-top: -8px !important;
  z-index: 10 !important;
  position: relative !important;
}

.cdk-overlay-pane .dms-dropdown {
  transform: translateY(16px) !important;
}

.dms-dropdown-checkbox .mdc-form-field > label {
  font-size: initial;
}

.sort-device .pc-icon-dropdown__container {
  box-shadow: none !important;
}

// Material pagination overrides
.pagination .mat-mdc-paginator-page-size-label,
.mat-mdc-paginator-range-label,
.mat-mdc-select-min-line {
  font-size: 16px;
}

.pagination .mat-mdc-paginator-page-size {
  margin-right: 12px;
  display: flex;
  align-items: center;
}

.pagination .mat-mdc-paginator-range-label {
  width: 87px;
  text-align: right;
}

.pagination .mat-mdc-paginator-container .mat-mdc-text-field-wrapper {
  width: 70px !important;
}

.pagination .mat-mdc-paginator-page-size-select {
  margin: 0 0 0 2px !important;
  width: 72px !important;
}

.pagination .mat-mdc-paginator-range-actions {
  align-items: right !important;
}

.pagination .mat-mdc-paginator-range-label {
  margin: 0 8px 0 4px !important;
}

.pagination .mat-mdc-paginator-navigation-previous {
  margin: 0px 8px 0px 8px !important;
}

.pagination .mat-mdc-tooltip-trigger {
  background-color: white !important;
  width: 12px !important;
}

.pagination .mdc-tooltip {
  display: none !important;
}

.pagination .mat-mdc-button-touch-target {
  width: 24px !important;
}

.pagination .mat-mdc-focus-indicator {
  width: 24px !important;
}

.pagination .mat-mdc-paginator-icon {
  margin-left: -12px !important;
}

.pagination .mat-mdc-button-ripple {
  display: none !important;
}

.pagination .mat-mdc-button-persistent-ripple {
  display: none !important;
}

.dropdown-button {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  cursor: pointer;
}

.sort-top-bar .dropdownMenu {
  position: absolute !important;
}

.sort-top-bar .dropdownMenuItem {
  width: 132px !important;
}

.sort-top-bar .pc-button--secondary {
  width: 164px !important;
}

.full-width {
  width: 100%;
}
